import React from 'react';

const LoaderApp = () => {
    return (
        <>
            <div style={{
                height: window.innerHeight,
                width:"100%",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexFlow: "column"
            }}>
                <img width="100px" height={"auto"} src={"../../images/logo.png"} alt="loader" />
                <div style={{
                    marginTop: 20,
                    width: 200,
                    height: 10
                }}>
                    {/* <ProgressBar animated striped variant="warning" now={progess} width={200} style={{
                        height: 5
                    }} /> */}
                </div>
            </div>
        </>
    )
}

export default LoaderApp