import appInfo from '../app-config';
import { call_post_api } from './api_helper';
import axios from 'axios';
export async function signIn(username, password, service, system_id) {
    const res = await axios.post(
        appInfo.api_url + '/login',
        Object.assign({}, { username: username }, { password: password }, { service: service }, { system_id: system_id })
    );
    return res.data;
    
}

export async function signInFromApp(data) {

    const res = await axios.post(
        appInfo.api_url + '/login',
        data
    )
    return res.data;

}



export async function getUser(token) {
    return call_post_api(
        appInfo.api_url + '/login/detail',
        token
    );

};

export async function createLoginTicket(service, system_id) {
    
     return call_post_api(
         appInfo.api_url + '/login/createLoginTicket',
         {
             service: service,
             system_id: system_id
         }
     );
};

export async function signOut() {
    return call_post_api(
        appInfo.api_url + '/logout');

};

